import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import config from '../../utils/siteConfig'

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showNav: null
    }
  }

  handleToggle = () => {
    this.setState({
      showNav: !this.state.showNav
    })
  }

  render() {

    return (
      <Nav>
        <LogoContainer to="/">
          <img src={'/logo.png'} alt="logo" height="30" /><LogoName>{config.siteTitle}</LogoName>
        </LogoContainer>

        <MenuSmallContainer>
          <MenuSmallButton onClick={this.handleToggle}>
            <MenuSmallSVG viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
            </MenuSmallSVG>
          </MenuSmallButton>
        </MenuSmallContainer>

        <MenuLargeContainer active={this.state.showNav}>
          <NavContainer>
            <NavLink to='/about'>About</NavLink>
            <NavLink to='/documentation'>Documentation</NavLink>
            <NavLink to='/support'>Support</NavLink>
            <NavLink to='/future-entofarmers-hub'>Future Hub</NavLink>
          </NavContainer>
        </MenuLargeContainer>
      </Nav>
    )
  }
}

export default Header;

// Component styles
const Nav = styled.div`${tw`px-32 py-8 flex items-center justify-between flex-wrap bg-white shadow p-6`}`
const LogoContainer = styled(Link)`${tw`flex items-center flex-shrink-0 text-black mr-6`}`
const LogoName = styled.span`${tw`font-bold ml-4 text-md md:text-lg tracking-tight `}`
const MenuSmallContainer = styled.div`${ tw`block ml-auto md:hidden cursor-pointer`};`
const MenuSmallButton = styled.div`${ tw`flex items-center px-3 py-2 border rounded`}`
const MenuSmallSVG = styled.svg`${tw`h-3 w-3`};`
const MenuLargeContainer = styled.div`
  ${tw`w-full md:w-auto md:flex-grow md:flex md:items-center`};
  display: ${props => (props.active ? 'flex' : 'none')};
`
const NavContainer = styled.div`${tw`flex flex-col text-md mt-4 mx-4 pt-4 border-t md:flex-row md:items-center md:mx-0 md:mt-0 md:pt-0 md:mr-4 lg:mr-8 md:border-0`}`
const NavLink = styled(Link)`${tw`block text-sm text-gray-800 p-4 lg:p-2 hover:underline`}`
