import React from 'react'
import { FaGitlab, FaEnvelope } from 'react-icons/fa'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import Container from './Container' 

export default class extends React.Component {

  render() {

    return (
      <Footer>
        <Container>
          <SocialIcon aria-label="gitlab" href="https://gitlab.com/entofarmers-club" target="_blank" rel="noopener noreferrer">
            <FaGitlab />
          </SocialIcon>
          <SocialIcon aria-label="email" href="mailto:arn@urbanlink.nl">
            <FaEnvelope />
          </SocialIcon>
        <SubFooter>
          <p>&copy; 2018-2020 <a href="https://urbanlink.nl" target="_blank" rel="noopener noreferrer">urbanlink.nl.</a> All right reserved.</p>
        </SubFooter>
      </Container>
      </Footer>
    )
  }
}

const Footer = styled.footer`${tw`py-8 text-center bg-smoke-lightest text-md`}`
const SocialIcon = styled.a`${tw`text-xl m-2 text-black`}`;
const SubFooter = styled.div`${tw`text-sm text-gray-600 my-5`}`;
