import React from 'react'
import Helmet from 'react-helmet'
import Normalize from 'react-normalize'
import styled, { createGlobalStyle } from 'styled-components'
import favicon from '../../../content/images/logo.png'
import config from '../../utils/siteConfig'
import Footer from './Footer'
import Header from './Header'

const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Source Sans Pro", sans-serif;
    line-height: 1.5;
    a {
      text-decoration: none;
      color: #0c5d9a;
    }

    h1,h2,h3,h4,h5,h6 {
      font-family: "Source Sans Pro", sans-serif;
    }

    textarea {
      border-radius: 3px;
      padding: 6px;
    }
    label {
      margin-bottom: 10px;
      display: block;
    }
    code, pre {
      white-space: pre-wrap;       /* css-3 */
      white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
      white-space: -pre-wrap;      /* Opera 4-6 */
      white-space: -o-pre-wrap;    /* Opera 7 */
      word-wrap: break-word; 
      color: #333;
      font-size: 0.85rem;
    }
    
  }
`

export default ({ children }) => (
  <React.Fragment>
    <Helmet>
      <html lang="nl" />
      <title>{config.siteTitle}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href={ favicon } />
    </Helmet>
    <Normalize />
    <GlobalStyle />
    <MainWrap>
      <Header />
      <PageWrap className="container">
        {children}
      </PageWrap>
      <Footer />
    </MainWrap>
  </React.Fragment>
)

const MainWrap = styled.div``
const PageWrap = styled.div``